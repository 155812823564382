import { Flex } from "@radix-ui/themes";
import { Navigate, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Document from "./pages/documents/Document";
import Documents from "./pages/documents/Documents";
import Navigator from "./pages/Navigator";
import Process from "./pages/processes/Process";
import Record from "./pages/records/Record";
import Records from "./pages/records/Records";

function LoggedInApp() {
  return (
    <Flex direction="row" height="100vh">
      <Sidebar />
      <Flex direction="column" flexGrow="1" overflowY="scroll">
        <Routes>
          <Route path="/" element={<Navigate replace to="/navigator" />} />
          <Route path="/navigator" element={<Navigator />} />
          <Route path="/processes/:processSlug" element={<Process />} />
          <Route path="/documents/:id" element={<Document />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/records/:recordSlug" element={<Records />} />
          <Route path="/records/:recordSlug/:recordId" element={<Record />} />
        </Routes>
      </Flex>
    </Flex>
  );
}

export default LoggedInApp;
