import { IconChecklist, IconTruckDelivery } from "@tabler/icons-react";
import { RecordsDatabase } from "./database/types";

export const designChangesProcessDescription =
  "Process to govern design changes that may affect customer requirements.";

export type DocumentRevisionStatus = "Draft" | "Pending Approval" | "Released";

export interface DocumentRevision {
  fileUrl: string;
  status: DocumentRevisionStatus;
  createdBy: string;
  createdAt: Date;
  number?: string;
  approvedBy?: string;
  approvedAt?: Date;
}

export type DocumentType = "Procedure" | "Work Instruction" | "Form" | "Record";

export interface Document {
  identifier: string;
  processId: string;
  type: DocumentType;
  title: string;
  description: string;
  complianceClause: string;
  owner: string;
  revisions: DocumentRevision[];
}

export const DOCUMENTS: { [id: string]: Document } = {
  "F-830-003": {
    identifier: "F-830-003",
    processId: "design-changes",
    type: "Form",
    title: "Design Change Request",
    description: "Request to change the design of a product",
    complianceClause: "AS9100 8.3.6",
    owner: "Engineering",
    revisions: [
      {
        number: "1",
        fileUrl: "https://www.iso9001help.co.uk/Change-request-form.pdf",
        status: "Released",
        createdBy: "John Doe",
        createdAt: new Date("2024-06-14"),
        approvedBy: "John Doe",
        approvedAt: new Date("2024-06-15"),
      },
      {
        number: "2",
        fileUrl: "https://www.iso9001help.co.uk/Change-request-form.pdf",
        status: "Pending Approval",
        createdBy: "Jane Doe",
        createdAt: new Date("2024-06-15"),
      },
      {
        fileUrl: "https://www.iso9001help.co.uk/Change-request-form.pdf",
        status: "Draft",
        createdBy: "John Doe",
        createdAt: new Date("2024-06-18"),
      },
    ],
  },
  "F-840-001": {
    identifier: "F-840-001",
    processId: "supplier-evaluation",
    type: "Form",
    title: "Supplier Self-assessment",
    description: "Self-assessment of a supplier's capabilities",
    complianceClause: "AS9100 8.4.0",
    owner: "Sourcing",
    revisions: [
      {
        number: "1",
        fileUrl:
          "https://www.iso9001help.co.uk/IAC%20Supplier%20Self-Assessment.pdf",
        status: "Released",
        createdBy: "John Doe",
        approvedBy: "Jane Doe",
        approvedAt: new Date("2024-06-15"),
        createdAt: new Date("2024-06-14"),
      },
      {
        fileUrl:
          "https://www.iso9001help.co.uk/IAC%20Supplier%20Self-Assessment.pdf",
        status: "Pending Approval",
        createdBy: "Jane Doe",
        createdAt: new Date("2024-06-12"),
      },
      {
        fileUrl:
          "https://www.iso9001help.co.uk/IAC%20Supplier%20Self-Assessment.pdf",
        status: "Draft",
        createdBy: "John Doe",
        createdAt: new Date("2024-06-10"),
      },
    ],
  },
};

type RecordTables = keyof RecordsDatabase["records"]["Tables"];

export type NSR =
  RecordsDatabase["records"]["Tables"]["nonconforming_service_reports"]["Row"];
export type Supplier = RecordsDatabase["records"]["Tables"]["suppliers"]["Row"];
export type SupplierEvaluation =
  RecordsDatabase["records"]["Tables"]["supplier_evaluations"]["Row"];
export type Record = NSR | Supplier | SupplierEvaluation;

export interface RecordField {
  apiName: string;
  name: string;
  description: string;
  type:
    | "string"
    | "number"
    | "date"
    | "timestamp"
    | "boolean"
    | "file[]"
    | "enum";
  // TODO: Field structure should include type, formatting, validation, and other details (e.g. enum values)
}

export interface RecordType {
  id: string;
  slug: string;
  icon: React.ReactElement;
  databaseTable: RecordTables;
  name: string;
  pluralName: string;
  shortName: string;
  description: string;
  fields: RecordField[];
}

export const RECORD_TYPES: { [slug: string]: RecordType } = {
  supplier: {
    id: "1",
    slug: "supplier",
    icon: <IconTruckDelivery />,
    databaseTable: "suppliers",
    name: "Supplier",
    pluralName: "Suppliers",
    shortName: "Suppliers",
    description: "All external providers associated with the company.",
    fields: [
      {
        apiName: "company_name",
        name: "Name",
        description: "The name of the supplier.",
        type: "string",
      },
      {
        apiName: "category",
        name: "Category",
        description: "The category of the supplier.",
        type: "enum",
      },
      {
        apiName: "status",
        name: "Status",
        description: "The current status of the supplier.",
        type: "enum",
      },
      {
        apiName: "primary_contact_email",
        name: "Primary Contact Email",
        description: "The email of the primary contact at the supplier.",
        type: "string",
      },
      {
        apiName: "primary_contact_name",
        name: "Primary Contact Name",
        description: "The name of the primary contact at the supplier.",
        type: "string",
      },
      {
        apiName: "location",
        name: "Location",
        description: "The location of the supplier.",
        type: "string",
      },
      {
        apiName: "iso9001_compliant",
        name: "ISO9001 Compliant",
        description: "Whether the supplier is ISO9001 compliant.",
        type: "boolean",
      },
      {
        apiName: "as9100_compliant",
        name: "AS9100 Compliant",
        description: "Whether the supplier is AS9100 compliant.",
        type: "boolean",
      },
      {
        apiName: "lead_times_score",
        name: "Lead Times Score",
        description: "The score for the supplier's lead times.",
        type: "number",
      },
      {
        apiName: "quality_score",
        name: "Quality Score",
        description: "The score for the supplier's quality.",
        type: "number",
      },
      {
        apiName: "price_score",
        name: "Price Score",
        description: "The score for the supplier's pricing.",
        type: "number",
      },
      {
        apiName: "created_at",
        name: "Created at",
        description: "The date the supplier record was created.",
        type: "timestamp",
      },
      {
        apiName: "updated_at",
        name: "Updated at",
        description: "The date the supplier record was last updated.",
        type: "timestamp",
      },
      {
        apiName: "provisional_at",
        name: "Provisional at",
        description: "The date the supplier became provisional.",
        type: "timestamp",
      },
    ],
  },
  "supplier-evaluation": {
    id: "2",
    slug: "supplier-evaluation",
    icon: <IconChecklist />,
    databaseTable: "supplier_evaluations",
    name: "Supplier Evaluation",
    pluralName: "Supplier Evaluations",
    shortName: "Supplier Evaluations",
    description: "Record of all past evaluations of external providers.",
    fields: [
      {
        apiName: "type",
        name: "Type",
        description: "The type of evaluation.",
        type: "string",
      },
      {
        apiName: "status",
        name: "Status",
        description: "The current status of the evaluation.",
        type: "enum",
      },
      {
        apiName: "supplier_id",
        name: "Supplier ID",
        description: "The ID of the supplier being evaluated.",
        type: "number",
      },
      {
        apiName: "evidence",
        name: "Evidence",
        description: "Array of evidence attachments for the evaluation.",
        type: "file[]",
      },
      {
        apiName: "created_at",
        name: "Created At",
        description: "The date when the evaluation was created.",
        type: "timestamp",
      },
      {
        apiName: "updated_at",
        name: "Updated At",
        description: "The date when the evaluation was last updated.",
        type: "timestamp",
      },
      {
        apiName: "sent_for_review_at",
        name: "Sent for Review At",
        description: "The date when the evaluation was sent for review.",
        type: "timestamp",
      },
      {
        apiName: "completed_at",
        name: "Completed At",
        description: "The date when the evaluation was completed.",
        type: "timestamp",
      },
    ],
  },
};

export const RECORD_TABLE_TO_SLUG = Object.fromEntries(
  Object.entries(RECORD_TYPES).map(([slug, recordType]) => [
    recordType.databaseTable,
    slug,
  ])
);

export enum SupplierStatus {
  PendingEvaluation = "Pending evaluation",
  Provisional = "Provisional",
  FirstTimePurchase = "First-time Purchase",
  Acceptable = "Acceptable",
  Unacceptable = "Unacceptable",
}

export enum SupplierEvaluationStatus {
  Draft = "Draft",
  InReview = "In review",
  Completed = "Completed",
}

export type ActionOperation = "create" | "update" | "delete";

export interface PermissionContext {
  record: Record;
}

export interface ActionPermissions {
  canView?: (context: PermissionContext) => boolean;
  canApply?: (context: PermissionContext) => boolean;
}

export interface FormConfig {
  fields: FormField[];
  canSubmit?: (context: FormLogicContext) => boolean;
}

interface FormLogicContext {
  fieldValues: { [apiName: string]: any };
}

interface EffectLogicContext extends FormLogicContext {
  // TODO type effect outputs better. Probably contains...
  //   - id: ID of created/updated/deleted record
  //   - fieldValues: latest values of created/updated record
  effectOutputs: { [effectId: string]: any };
}

export interface FormField {
  apiName: string;
  name: string;
  type: RecordField["type"] | "record";
  displayConfig?: any; // TODO type this
  visible?: boolean;
  required?: boolean;
  // TODO support prefilling fields from record table view
  prefill?: (context: FormLogicContext) => any;
  validator?: (value?: any) => boolean;
}

interface LiteralRecordFieldValue {
  type: "literalRecordFieldValue";
  value: any;
}

interface FormToRecordField {
  type: "formToRecordField";
  formFieldApiName: string;
}

interface LogicToRecordField {
  type: "logicToRecordField";
  logic: (context: EffectLogicContext) => any;
}

type RecordFieldMapping =
  | LiteralRecordFieldValue
  | FormToRecordField
  | LogicToRecordField;

interface ModifyRecordEffect {
  type: "modifyRecord";
  operation: ActionOperation;
  recordTypeSlug: string;

  // Only present for Update and Delete operations
  recordId?: RecordFieldMapping;
  // Only present for Create and Update operations
  recordFieldValueMapping: { [recordFieldApiName: string]: RecordFieldMapping };
}

interface CreateTaskEffect {
  type: "createTask";
  title: string;
  description: string;
  assignee: string;
}

interface SendNotificationEffect {
  type: "sendNotification";
  title: string;
  description: string;
}

type ActionEffect = { id: string } & (
  | ModifyRecordEffect
  | CreateTaskEffect
  | SendNotificationEffect
);

export interface ActionType {
  id: string;
  recordTypeSlug: string;
  primaryOperation: ActionOperation;
  title: string;
  form: FormConfig;
  effects: ActionEffect[];
  permission?: (context: PermissionContext) => boolean;
}

export interface Status {
  value: SupplierEvaluationStatus;
  color: string;
}

export interface Workflow {
  id: string;
  primaryRecordTypeSlug: string;
  title: string;
  description: string;
  firstNodeId: string;
  nodes: { [id: string]: WorkflowNode };
}

interface WorkflowNode {
  id: string;
  type: "status" | "action";
  status?: Status;
  action?: ActionType;
  nextNodeIds: string[];
}

export const ACTION_TYPES: { [id: string]: ActionType } = {
  "1": {
    id: "1",
    recordTypeSlug: "supplier-evaluation",
    primaryOperation: "create",
    title: "New supplier evaluation",
    form: {
      fields: [
        {
          apiName: "supplier_name",
          name: "Supplier Name",
          type: "string",
          required: true,
        },
        {
          apiName: "category",
          name: "Category",
          type: "enum",
          required: true,
        },
        {
          apiName: "primary_contact_name",
          name: "Primary Contact Name",
          type: "string",
          required: true,
        },
        {
          apiName: "primary_contact_email",
          name: "Primary Contact Email",
          type: "string",
          required: true,
        },
        {
          apiName: "location",
          name: "Location",
          type: "string",
          required: true,
        },
        {
          apiName: "iso9001_compliant",
          name: "ISO-9001 Compliant?",
          type: "boolean",
          required: true,
        },
        {
          apiName: "as9100_compliant",
          name: "AS9100 Compliant?",
          type: "boolean",
          required: true,
        },
        {
          apiName: "lead_times_score",
          name: "Lead Time Score",
          type: "number",
          required: true,
          validator: (value) => value >= 0 && value <= 5,
        },
        {
          apiName: "quality_score",
          name: "Quality Score",
          type: "number",
          required: true,
          validator: (value) => value >= 0 && value <= 5,
        },
        {
          apiName: "price_score",
          name: "Price Score",
          type: "number",
          required: true,
          validator: (value) => value >= 0 && value <= 5,
        },
        {
          apiName: "evidence",
          name: "Evidence",
          type: "file[]",
          required: true,
        },
      ],
    },
    effects: [
      // Create supplier
      {
        id: "1",
        type: "modifyRecord",
        operation: "create",
        recordTypeSlug: "supplier",
        recordFieldValueMapping: {
          name: {
            type: "formToRecordField",
            formFieldApiName: "supplier_name",
          },
          category: {
            type: "formToRecordField",
            formFieldApiName: "category",
          },
          primary_contact_name: {
            type: "formToRecordField",
            formFieldApiName: "primary_contact_name",
          },
          primary_contact_email: {
            type: "formToRecordField",
            formFieldApiName: "primary_contact_email",
          },
          location: {
            type: "formToRecordField",
            formFieldApiName: "location",
          },
          iso9001_compliant: {
            type: "formToRecordField",
            formFieldApiName: "iso9001_compliant",
          },
          as9100_compliant: {
            type: "formToRecordField",
            formFieldApiName: "as9100_compliant",
          },
          lead_times_score: {
            type: "formToRecordField",
            formFieldApiName: "lead_times_score",
          },
          quality_score: {
            type: "formToRecordField",
            formFieldApiName: "quality_score",
          },
          price_score: {
            type: "formToRecordField",
            formFieldApiName: "price_score",
          },
          evidence: {
            type: "formToRecordField",
            formFieldApiName: "evidence",
          },
        },
      },
      // Create supplier evaluation
      {
        id: "2",
        type: "modifyRecord",
        operation: "create",
        recordTypeSlug: "supplier-evaluation",
        recordFieldValueMapping: {
          // Will be automatically added by Workflow builder
          status: {
            type: "literalRecordFieldValue",
            value: "Draft",
          },
          type: {
            type: "literalRecordFieldValue",
            value: "Initial",
          },
          supplier_id: {
            type: "logicToRecordField",
            logic: (ctx) => ctx.effectOutputs["1"].id,
          },
          evidence: {
            type: "formToRecordField",
            formFieldApiName: "evidence",
          },
        },
      },
    ],
  },
  "2": {
    id: "2",
    recordTypeSlug: "supplier-evaluation",
    primaryOperation: "update",
    title: "Edit supplier evaluation",
    permission: (context: PermissionContext) => {
      return context.record.status === SupplierEvaluationStatus.Draft;
    },
    form: {
      fields: [],
    },
    effects: [
      // Update supplier evaluation
    ],
  },
  "3": {
    id: "3",
    recordTypeSlug: "supplier-evaluation",
    primaryOperation: "update",
    title: "Send for review",
    permission: (context: PermissionContext) => {
      return context.record.status === SupplierEvaluationStatus.Draft;
    },
    form: {
      fields: [
        {
          apiName: "evaluation_id",
          name: "Supplier Evaluation",
          type: "record",
          required: true,
        },
        {
          apiName: "confirm",
          name: "Are you sure you want to send this evaluation for review?",
          type: "boolean",
        },
      ],
      canSubmit: (ctx) => ctx.fieldValues["confirm"],
    },
    effects: [
      // Update supplier evaluation
      {
        id: "1",
        type: "modifyRecord",
        operation: "update",
        recordTypeSlug: "supplier-evaluation",
        recordId: {
          type: "formToRecordField",
          formFieldApiName: "evaluation_id",
        },
        recordFieldValueMapping: {
          // Will be automatically added by Workflow builder
          status: {
            type: "literalRecordFieldValue",
            value: "In review",
          },
        },
      },
      // Create Task for reviewer
      {
        id: "2",
        type: "createTask",
        title: "Review supplier evaluation",
        description: "A new supplier evaluation is ready for your review.",
        assignee: "...",
      },
    ],
  },
  "4": {
    id: "4",
    recordTypeSlug: "supplier-evaluation",
    primaryOperation: "update",
    title: "Complete evaluation",
    permission: (context: PermissionContext) => {
      return context.record.status === SupplierEvaluationStatus.InReview;
    },
    form: {
      fields: [
        {
          apiName: "evaluation_id",
          name: "Supplier Evaluation",
          type: "record",
          visible: false,
          required: true,
        },
        {
          apiName: "confirm",
          name: "Confirm?",
          type: "boolean",
        },
      ],
      canSubmit: (ctx) => ctx.fieldValues["confirm"],
    },
    effects: [
      // Update supplier evaluation
      {
        id: "1",
        type: "modifyRecord",
        operation: "update",
        recordTypeSlug: "supplier-evaluation",
        recordId: {
          type: "formToRecordField",
          formFieldApiName: "evaluation_id",
        },
        recordFieldValueMapping: {
          // Will be automatically added by Workflow builder
          status: {
            type: "literalRecordFieldValue",
            value: "Completed",
          },
        },
      },
      // Update supplier
      {
        id: "2",
        type: "modifyRecord",
        operation: "update",
        recordTypeSlug: "supplier",
        recordId: {
          type: "logicToRecordField",
          logic: (ctx) => ctx.effectOutputs["1"].fieldValues.supplierId,
        },
        recordFieldValueMapping: {
          status: {
            type: "literalRecordFieldValue",
            value: "Provisional",
          },
        },
      },
    ],
  },
};

const DEMO_WORKFLOW: Workflow = {
  id: "1",
  primaryRecordTypeSlug: "supplier-evaluation",
  title: "Supplier Evaluation Workflow",
  description: "Workflow for supplier evaluations",
  firstNodeId: "1",
  nodes: {
    "1": {
      id: "1",
      type: "action",
      // New supplier evaluation
      action: ACTION_TYPES["1"],
      nextNodeIds: ["2"],
    },
    "2": {
      id: "2",
      type: "status",
      status: {
        value: SupplierEvaluationStatus.Draft,
        color: "iris",
      },
      nextNodeIds: ["3", "4"],
    },
    "3": {
      id: "3",
      type: "action",
      // Edit supplier evaluation
      action: ACTION_TYPES["2"],
      nextNodeIds: ["2"],
    },
    "4": {
      id: "3",
      type: "action",
      // Send for review
      action: ACTION_TYPES["3"],
      nextNodeIds: ["5"],
    },
    "5": {
      id: "4",
      type: "status",
      status: {
        value: SupplierEvaluationStatus.InReview,
        color: "yellow",
      },
      nextNodeIds: ["6"],
    },
    "6": {
      id: "5",
      type: "action",
      // Complete evaluation
      action: ACTION_TYPES["3"],
      nextNodeIds: ["7"],
    },
    "7": {
      id: "6",
      type: "status",
      status: {
        value: SupplierEvaluationStatus.Completed,
        color: "green",
      },
      nextNodeIds: [],
    },
  },
};

export const getActionTypes = (
  recordTypeSlug: string,
  operations?: ActionOperation[]
): ActionType[] => {
  return Object.values(ACTION_TYPES).filter(
    (actionType) =>
      actionType.recordTypeSlug === recordTypeSlug &&
      (operations === undefined ||
        operations.includes(actionType.primaryOperation))
  );
};
