import {
  Badge,
  ContextMenu,
  Flex,
  IconButton,
  Separator,
  Text,
} from "@radix-ui/themes";
import { IconPencil } from "@tabler/icons-react";
import { Handle, Node, NodeProps, Position } from "@xyflow/react";
import { CSSProperties } from "react";
import { THEME_COLOR } from "../../theme";

export type CustomNodeType = Node<
  {
    whenBadge?: boolean;
    title: string;
    description: React.ReactNode;
    icon: React.ReactNode;
    color?: string;
  },
  "custom"
>;

const defaultHandleStyle = {
  zIndex: 1,
  backgroundColor: "var(--color-panel-solid)",
  border: "1px solid var(--accent-9)",
  width: "var(--space-1)",
  height: "var(--space-1)",
};

export default function CustomNode({
  data,
  selected,
  selectable,
  isConnectable,
}: NodeProps<CustomNodeType>) {
  const handleStyle: CSSProperties = {
    ...defaultHandleStyle,
    width: isConnectable ? "var(--space-1)" : "0px",
    height: isConnectable ? "var(--space-1)" : "0px",
    visibility: isConnectable ? "visible" : "hidden",
  };
  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>
        <Flex
          direction="column"
          width="260px"
          height="70px"
          style={{
            backgroundColor: "var(--color-panel-solid)",
            border: selected
              ? "1px solid var(--accent-9)"
              : "1px solid var(--gray-6)",
            boxShadow: selected
              ? "0 0 0 1px var(--accent-10), 0 0 1px 2px var(--accent-a6)"
              : "none",
            borderRadius: "var(--radius-3)",
          }}
        >
          {data.whenBadge && (
            <Flex
              direction="column"
              align="center"
              style={{
                position: "absolute",
                top: -35,
                left: 105,
                height: "40px",
              }}
            >
              <Badge
                variant={selected ? "solid" : "surface"}
                color={selected ? THEME_COLOR : "gray"}
              >
                <Text size="1" style={{ textTransform: "uppercase" }}>
                  When
                </Text>
              </Badge>
              <Separator
                size="2"
                orientation="vertical"
                style={{
                  backgroundColor: selected
                    ? "var(--accent-9)"
                    : "var(--gray-6)",
                  width: selected ? "2px" : "1px",
                  zIndex: -1,
                }}
              />
            </Flex>
          )}
          <Flex direction="row" align="center" justify="between" p="1">
            <Flex direction="row" align="center" gap="2">
              <Badge
                variant="soft"
                color={(data.color as any) ?? "blue"}
                style={{ padding: "2px" }}
              >
                {data.icon}
              </Badge>
              <Text size="1" weight="bold">
                {data.title}
              </Text>
            </Flex>
            <Flex direction="row" align="center" gap="2">
              {selectable && (
                <IconButton variant="ghost" color="gray" size="1">
                  <IconPencil />
                </IconButton>
              )}
            </Flex>
          </Flex>

          <Separator size="4" />

          <Flex direction="column" flexGrow="1" gap="1" p="2">
            <Text size="1" color="gray">
              {data.description}
            </Text>
          </Flex>

          <Handle type="target" position={Position.Top} style={handleStyle} />
          <Handle
            type="source"
            position={Position.Bottom}
            id="a"
            style={handleStyle}
          />
        </Flex>
      </ContextMenu.Trigger>
      <ContextMenu.Content>
        <ContextMenu.Item>Edit</ContextMenu.Item>
        <ContextMenu.Item>Duplicate</ContextMenu.Item>
        <ContextMenu.Separator />
        <ContextMenu.Item shortcut="⌫" color="red">
          Delete
        </ContextMenu.Item>
      </ContextMenu.Content>
    </ContextMenu.Root>
  );
}
