import { Session } from "@supabase/supabase-js";
import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../supabaseClient";

type SessionState = "uninitialized" | null | Session;

interface SupabaseContextType {
  session: SessionState;
  setSession: React.Dispatch<React.SetStateAction<SessionState>>;
}

const SupabaseContext = createContext<SupabaseContextType | undefined>(
  undefined
);

/**
 * Provides access to the Supabase client, for use in database calls.
 * Accessing user and auth information should be done using @usebasejump/react hooks.
 */
export function SupabaseProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<SessionState>("uninitialized");

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <SupabaseContext.Provider value={{ session, setSession }}>
      {children}
    </SupabaseContext.Provider>
  );
}

export function useSupabase() {
  const context = useContext(SupabaseContext);
  if (context === undefined) {
    throw new Error("useSupabase must be used within a SupabaseProvider");
  }
  return context;
}

export function useSupabaseSession() {
  const supabase = useSupabase();
  if (supabase.session == null || supabase.session === "uninitialized") {
    return undefined;
  }
  return supabase.session;
}
