import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  DataList,
  Flex,
  Grid,
  Heading,
  IconButton,
  Tabs,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import {
  IconArrowRightRhombus,
  IconCalendar,
  IconChartBar,
  IconChevronRight,
  IconFile,
  IconFilePlus,
  IconFileText,
  IconId,
  IconPencil,
  IconPlus,
  IconSchool,
  IconSquareCheck,
  IconX,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppToolbar from "../../components/AppToolbar";
import DetailCard from "../../components/DetailCard";
import SaveProcessDialog from "../../components/dialogs/SaveProcessDialog";
import List from "../../components/List";
import NonIdealState from "../../components/NonIdealState";
import { useDesignChanges } from "../../contexts/DesignChangesContext";
import { designChangesProcessDescription, DOCUMENTS } from "../../demoData";
import ProcessCompliance from "./ProcessCompliance";
import ProcessDocuments from "./ProcessDocuments";
import { ProcessTab, ProcessTabHeader } from "./ProcessTab";
import { Workflow } from "./Workflow";

const Process: React.FC = () => {
  const navigate = useNavigate();
  const { designChangesProcess } = useDesignChanges();
  const [isEditing, setIsEditing] = useState(false);
  const [cancelSemaphore, setCancelSemaphore] = useState(0);
  const toggleEditing = () => setIsEditing(!isEditing);
  const resetWorkflow = () => {
    setCancelSemaphore(cancelSemaphore + 1);
    setIsEditing(false);
  };

  const title = designChangesProcess?.name ?? "Design Changes";
  const description =
    designChangesProcess?.description ?? designChangesProcessDescription;

  const documents = Object.values(DOCUMENTS).filter(
    (doc) => doc.processId === designChangesProcess?.id
  );

  return (
    <Flex direction="column">
      <AppToolbar
        title={
          <Flex align="center" gap="1">
            <Button variant="ghost" color="gray" asChild>
              <Link to="/processes">
                <IconArrowRightRhombus />
                <Text>Processes</Text>
              </Link>
            </Button>
            <IconChevronRight />
            <Text>{title}</Text>
          </Flex>
        }
      />
      <Container
        px="5"
        py={{ md: "5", xl: "7" }}
        align={{ md: "left", xl: "center" }}
      >
        <Flex direction="column" gap="5">
          <Flex align="center" justify="between">
            <Flex direction="column" gap="1">
              <Text
                color="gray"
                size="1"
                weight="bold"
                style={{ textTransform: "uppercase", color: "var(--gray-10)" }}
              >
                Process
              </Text>
              <Heading size="7">{title}</Heading>
              <Text color="gray" size="4">
                {description}
              </Text>
            </Flex>
            <Flex align="center" gap="2">
              {!isEditing && (
                <Button variant="soft" color="gray" onClick={toggleEditing}>
                  <IconPencil />
                  Edit
                </Button>
              )}
              {isEditing && (
                <>
                  <Button variant="soft" color="gray" onClick={resetWorkflow}>
                    <IconX />
                    Cancel
                  </Button>
                  <SaveProcessDialog onSave={toggleEditing} />
                </>
              )}
            </Flex>
          </Flex>
          <Tabs.Root defaultValue="overview">
            <Tabs.List>
              <Tabs.Trigger value="overview">
                <Flex align="center" gap="1">
                  <IconId />
                  Overview
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="workflow">
                <Flex align="center" gap="1">
                  <IconArrowRightRhombus />
                  Workflow
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="documents">
                <Flex align="center" gap="1">
                  <IconFileText />
                  Documents
                  {documents.length > 0 && (
                    <Badge size="1" color="gray">
                      {documents.length}
                    </Badge>
                  )}
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="compliance">
                <Flex align="center" gap="1">
                  <IconSquareCheck />
                  Compliance
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="training">
                <Flex align="center" gap="1">
                  <IconSchool />
                  Training
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="metrics">
                <Flex align="center" gap="1">
                  <IconChartBar />
                  Metrics
                </Flex>
              </Tabs.Trigger>
            </Tabs.List>
            <Box pt="3">
              <ProcessTab value="overview">
                <ProcessTabHeader title="Overview" />
                <Grid columns="2" gap="4" maxWidth="900px">
                  <DetailCard
                    title="Basic Information"
                    icon={<IconId />}
                    actions={
                      <Tooltip content="Edit information">
                        <IconButton
                          color="gray"
                          variant="ghost"
                          aria-label="Edit information"
                        >
                          <IconPencil />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <DataList.Root>
                      <DataList.Item>
                        <DataList.Label>Process Name</DataList.Label>
                        <DataList.Value>
                          {designChangesProcess?.name}
                        </DataList.Value>
                      </DataList.Item>
                      <DataList.Item>
                        <DataList.Label>Process Owner</DataList.Label>
                        <DataList.Value>
                          <Flex align="center" gap="2">
                            <Avatar fallback="VS" size="1" />
                            Vipul Shekhawat
                          </Flex>
                        </DataList.Value>
                      </DataList.Item>
                      <DataList.Item>
                        <DataList.Label>Department</DataList.Label>
                        <DataList.Value>
                          <Badge>Sourcing</Badge>
                        </DataList.Value>
                      </DataList.Item>
                      <DataList.Item>
                        <DataList.Label>Created On</DataList.Label>
                        <DataList.Value>
                          <Flex align="center" gap="1">
                            <IconCalendar />
                            <Text>
                              {designChangesProcess?.createdAt.toLocaleDateString() ??
                                "Sept 30th, 2024"}
                            </Text>
                          </Flex>
                        </DataList.Value>
                      </DataList.Item>
                    </DataList.Root>
                  </DetailCard>
                  <DetailCard title="Workflow" icon={<IconArrowRightRhombus />}>
                    {/* <NonIdealState
                      size="small"
                      icon={<IconArrowRightRhombus />}
                      title="No workflow found"
                      description="Create a workflow for this process by adding steps and tasks."
                      action={
                        <Button size="1" variant="soft">
                          <IconPlus />
                          Create workflow
                        </Button>
                      }
                    /> */}
                    <List.Root>
                      <List.Item>
                        <Flex align="center" justify="between" width="100%">
                          <Flex align="center" gap="1">
                            <IconArrowRightRhombus />
                            <Text>Supplier Evaluation</Text>
                          </Flex>
                          <IconChevronRight />
                        </Flex>
                      </List.Item>
                    </List.Root>
                  </DetailCard>
                  <DetailCard
                    title="Documents"
                    icon={<IconFileText />}
                    actions={
                      <>
                        <Tooltip content="Add document">
                          <IconButton
                            size="1"
                            aria-label="Add document"
                            color="gray"
                            variant="ghost"
                          >
                            <IconFilePlus />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  >
                    {documents.length > 0 ? (
                      <List.Root>
                        {documents.map((doc) => (
                          <>
                            <List.Item
                              key={doc.identifier}
                              onClick={() => {
                                navigate(`/documents/${doc.identifier}`);
                              }}
                            >
                              <Flex
                                align="center"
                                justify="between"
                                width="100%"
                              >
                                <Flex align="center" gap="1">
                                  <IconFileText />
                                  <Text>{doc.title}</Text>
                                </Flex>
                                <IconChevronRight />
                              </Flex>
                            </List.Item>
                          </>
                        ))}
                      </List.Root>
                    ) : (
                      <NonIdealState
                        size="small"
                        icon={<IconFile />}
                        title="No documents found"
                        description="Manage documents related to this process."
                        action={
                          <Button size="1" variant="soft">
                            <IconFilePlus />
                            Add document
                          </Button>
                        }
                      />
                    )}
                  </DetailCard>
                  <DetailCard title="Compliance" icon={<IconSquareCheck />}>
                    <List.Root>
                      <List.Item>
                        <Flex align="center" justify="between" width="100%">
                          <Flex align="center" gap="1">
                            <IconSquareCheck />
                            <Text>AS9100 Clause 8.4</Text>
                          </Flex>
                          <IconChevronRight />
                        </Flex>
                      </List.Item>
                    </List.Root>
                  </DetailCard>
                  <DetailCard title="Training" icon={<IconSchool />}>
                    <NonIdealState
                      size="small"
                      icon={<IconSchool />}
                      title="No training"
                      description="Add training materials and resources for this process."
                      action={
                        <Button size="1" variant="soft">
                          <IconPlus />
                          Add training material
                        </Button>
                      }
                    />
                  </DetailCard>
                  <DetailCard title="Metrics" icon={<IconChartBar />}>
                    <NonIdealState
                      size="small"
                      icon={<IconChartBar />}
                      title="No metrics available"
                      description="Track and analyze key performance indicators for this process."
                      action={
                        <Button size="1" variant="soft">
                          <IconPlus />
                          Add metric
                        </Button>
                      }
                    />
                  </DetailCard>
                </Grid>
              </ProcessTab>
              <ProcessTab value="workflow">
                <ProcessTabHeader title="Workflow" />
                <Workflow
                  isEditing={isEditing}
                  cancelSemaphore={cancelSemaphore}
                />
              </ProcessTab>
              <ProcessTab value="documents">
                <ProcessTabHeader
                  title="Documents"
                  actions={
                    <>
                      <Button variant="soft">
                        <IconPlus />
                        Add document
                      </Button>
                    </>
                  }
                />
                <ProcessDocuments documents={documents} />
              </ProcessTab>
              <ProcessTab value="compliance">
                <ProcessTabHeader
                  title="Compliance"
                  actions={
                    <>
                      <Button variant="soft">
                        <IconPlus />
                        Add compliance mapping
                      </Button>
                    </>
                  }
                />
                <ProcessCompliance
                  mappings={[
                    {
                      framework: "AS9100",
                      clause: "8.4",
                      description:
                        "Control of Externally Provided Processes, Products, and Services",
                    },
                  ]}
                />
                {/* <NonIdealState
                  icon={<IconSquareCheck />}
                  title="No compliance items found"
                  description="Ensure regulatory compliance by adding and tracking compliance requirements for this process."
                  action={
                    <Button>
                      <IconPlus />
                      Add compliance item
                    </Button>
                  }
                /> */}
              </ProcessTab>
              <ProcessTab value="training">
                <ProcessTabHeader
                  title="Training"
                  actions={
                    <>
                      <Button variant="soft">
                        <IconPlus />
                        Add training
                      </Button>
                    </>
                  }
                />
                <NonIdealState
                  icon={<IconSchool />}
                  title="No training found"
                  description="Implement this process by creating customized training content and tracking employee training completion."
                  action={
                    <Button>
                      <IconPlus />
                      Add training
                    </Button>
                  }
                />
              </ProcessTab>
              <ProcessTab value="metrics">
                <ProcessTabHeader
                  title="Metrics"
                  actions={
                    <>
                      <Button variant="soft">
                        <IconPlus />
                        Add metric
                      </Button>
                    </>
                  }
                />
                <NonIdealState
                  icon={<IconChartBar />}
                  title="No metrics found"
                  description="Track and analyze key performance indicators for this process by adding custom metrics."
                  action={
                    <Button>
                      <IconPlus />
                      Add metric
                    </Button>
                  }
                />
              </ProcessTab>
            </Box>
          </Tabs.Root>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Process;
