import { Flex, Heading, Tabs } from "@radix-ui/themes";

export const ProcessTab: React.FC<{
  children: React.ReactNode;
  value: string;
}> = ({ children, value }) => {
  return (
    <Tabs.Content value={value}>
      <Flex direction="column" gap="3">
        {children}
      </Flex>
    </Tabs.Content>
  );
};

export const ProcessTabHeader: React.FC<{
  title: string;
  actions?: React.ReactNode;
}> = ({ title, actions }) => {
  return (
    <Flex align="center" justify="between">
      <Heading size="5">{title}</Heading>
      <Flex align="center" gap="2">
        {actions}
      </Flex>
    </Flex>
  );
};
