import * as Form from "@radix-ui/react-form";
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Container,
  Dialog,
  Flex,
  Heading,
  ScrollArea,
  Select,
  Spinner,
  Switch,
  Text,
  TextField,
} from "@radix-ui/themes";
import {
  IconChevronRight,
  IconFilter,
  IconListDetails,
  IconPencil,
  IconPlus,
  IconSortDescending,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react";
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppToolbar from "../../components/AppToolbar";
import { getFormatterForFieldType } from "../../components/tables/formatters";
import { getRendererForFieldType } from "../../components/tables/renderers";
import {
  ActionType,
  FormField,
  getActionTypes,
  Record,
  RECORD_TABLE_TO_SLUG,
  RECORD_TYPES,
  RecordField,
  RecordType,
} from "../../demoData";
import { supabaseRecords } from "../../supabaseClient";

const Records = () => {
  const { recordSlug } = useParams();
  const [selectedRecords, setSelectedRecords] = useState<Record[]>([]);
  const [records, setRecords] = useState<Record[]>([]);
  const [loading, setLoading] = useState(true);

  const recordType = recordSlug ? RECORD_TYPES[recordSlug] : undefined;

  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true);
      if (!recordType) {
        return [];
      }
      const { data, error } = await supabaseRecords
        .schema("records")
        .from(recordType.databaseTable)
        .select("*");

      if (error) {
        console.error("Error fetching records:", error);
      } else {
        setRecords(data || []);
      }
      setLoading(false);
    };

    fetchRecords();
  }, [recordType]);

  if (!recordType) {
    return <div>Loading...</div>;
  }

  return (
    <Flex direction="column">
      <AppToolbar
        title={
          <Flex align="center" gap="1">
            <Button variant="ghost" color="gray">
              <IconListDetails />
              <Text>Records</Text>
            </Button>
            <IconChevronRight />
            <Button variant="ghost" color="gray" asChild>
              <Link to={`/records/${recordType.slug}`}>
                <Text>{recordType.pluralName}</Text>
              </Link>
            </Button>
          </Flex>
        }
      />
      <Container
        px="5"
        py={{ md: "5", xl: "7" }}
        align={{ md: "left", xl: "center" }}
      >
        <Flex direction="column" gap="2">
          <Flex direction="column" gap="1">
            <Heading size="7">{recordType.pluralName}</Heading>
            <Text color="gray" size="4">
              {recordType.description}
            </Text>
          </Flex>
          <RecordsToolbar
            recordType={recordType}
            selectedRecords={selectedRecords}
          />
          <RecordsTable
            recordType={recordType}
            records={records}
            onSelectionChange={setSelectedRecords}
            loading={loading}
          />
        </Flex>
      </Container>
    </Flex>
  );
};

const RecordsToolbar: React.FC<{
  recordType: RecordType;
  selectedRecords: Record[];
}> = ({ recordType, selectedRecords }) => {
  const createActions = getActionTypes(recordType.slug, ["create"]);
  const modifyActions = getActionTypes(recordType.slug, ["update", "delete"]);
  return (
    <Flex justify="between" align="center" py="2">
      <Flex gap="2">
        <Button variant="outline" color="gray">
          <IconSortDescending />
          Sort
        </Button>
        <Button variant="outline" color="gray">
          <IconFilter />
          Filter
        </Button>
      </Flex>
      <RecordActions
        reload={() => {}}
        selectedRecords={selectedRecords}
        createActions={createActions}
        modifyActions={modifyActions}
      />
    </Flex>
  );
};

const RecordActions: React.FC<{
  reload: () => void;
  selectedRecords: Record[];
  createActions: ActionType[];
  modifyActions: ActionType[];
}> = ({ reload, selectedRecords, createActions, modifyActions }) => {
  let actions = [];
  if (selectedRecords.length > 0) {
    actions.push(
      ...modifyActions.map((action) => (
        <ActionButton
          key={action.id}
          action={action}
          selectedRecords={selectedRecords}
        />
      ))
    );
  } else {
    actions.push(
      ...createActions.map((action) => (
        <ActionButton
          key={action.id}
          action={action}
          selectedRecords={selectedRecords}
        />
      ))
    );
  }
  return <Flex gap="2">{actions}</Flex>;
};

const ActionButton: React.FC<{
  action: ActionType;
  selectedRecords: Record[];
}> = ({ action, selectedRecords }) => {
  const operation = action.primaryOperation;
  const permission = action.permission;

  if (permission && !permission({ record: selectedRecords[0] })) {
    return null;
  }

  let buttonProps: ButtonProps = {};
  let icon: React.ReactNode | undefined;
  if (operation === "create") {
    buttonProps = {};
    icon = <IconPlus />;
  } else if (operation === "update") {
    buttonProps = {
      variant: "soft",
    };
    icon = <IconPencil />;
  } else if (operation === "delete") {
    buttonProps = {
      variant: "soft",
      color: "red",
    };
    icon = <IconTrash />;
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Button {...buttonProps}>
          {icon}
          {action.title}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Form.Root>
          <Dialog.Title>{action.title}</Dialog.Title>
          <ActionForm action={action} selectedRecords={selectedRecords} />
          <Flex justify="end" gap="2">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Form.Submit asChild>
              <Button>Submit</Button>
            </Form.Submit>
          </Flex>
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const ActionForm: React.FC<{
  action: ActionType;
  selectedRecords: Record[];
}> = ({ action, selectedRecords }) => {
  const formFields = action.form.fields.map((field) => (
    <ActionFormField key={field.apiName} field={field} />
  ));
  return (
    <ScrollArea scrollbars="vertical" style={{ maxHeight: "400px" }}>
      <Flex direction="column" gap="2" mr="5" mb="5">
        {formFields}
      </Flex>
    </ScrollArea>
  );
};

const ActionFormField: React.FC<{
  field: FormField;
}> = ({ field }) => {
  if (field.type === "record") {
    return null;
  }

  let inputComponent: React.ReactNode;
  if (field.type === "string") {
    inputComponent = <TextField.Root placeholder="Enter value" size="2" />;
  } else if (field.type === "number") {
    inputComponent = (
      <TextField.Root type="number" placeholder="123" size="2" />
    );
  } else if (field.type === "boolean") {
    inputComponent = <Switch />;
  }
  // TODO bring this back
  // else if (field.type === "record") {
  //   inputComponent = (
  //     <DropdownMenu.Root>
  //       <DropdownMenu.Trigger>
  //         <Button variant="soft">Select Record</Button>
  //       </DropdownMenu.Trigger>
  //       <DropdownMenu.Content>
  //         <DropdownMenu.Item>Record 1</DropdownMenu.Item>
  //         <DropdownMenu.Item>Record 2</DropdownMenu.Item>
  //         <DropdownMenu.Item>Record 3</DropdownMenu.Item>
  //       </DropdownMenu.Content>
  //     </DropdownMenu.Root>
  //   );
  // }
  else if (field.type === "enum") {
    inputComponent = (
      <Select.Root defaultValue="optionA">
        <Select.Trigger />
        <Select.Content>
          <Select.Item value="optionA">Electrical</Select.Item>
          <Select.Item value="optionB">Mechanical</Select.Item>
          <Select.Item value="optionC">Electro-mechanical</Select.Item>
        </Select.Content>
      </Select.Root>
    );
  } else if (field.type === "file[]") {
    inputComponent = (
      <Flex direction="column" gap="2">
        <Button variant="soft">
          <IconUpload /> Upload Files
        </Button>
      </Flex>
    );
  }
  // else if (field.type === "date") {
  //   inputComponent = <DatePicker />;
  // } else if (field.type === "timestamp") {
  //   inputComponent = <DatePicker />;
  // }

  return (
    <Form.Field name={field.apiName}>
      <Flex align="baseline" direction="column" gap="1">
        <Form.Label style={{ fontSize: "var(--font-size-2)" }}>
          {field.name}
        </Form.Label>
        {/* <Form.Message className="FormMessage" match="valueMissing">
          Please enter a value
        </Form.Message>
        <Form.Message className="FormMessage" match="typeMismatch">
          Please provide a valid value
        </Form.Message> */}
        <Form.Control asChild style={{ width: "100%" }}>
          {inputComponent}
        </Form.Control>
      </Flex>
    </Form.Field>
  );
};

const CustomCheckbox = (props: ICellRendererParams) => {
  const [isChecked, setIsChecked] = useState(props.node.isSelected());

  useEffect(() => {
    const updateChecked = () => {
      setIsChecked(props.node.isSelected());
    };
    props.api.addEventListener("selectionChanged", updateChecked);
    return () => {
      props.api.removeEventListener("selectionChanged", updateChecked);
    };
  }, [props.api, props.node]);

  const handleClick = () => {
    props.node.setSelected(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <Flex
      align="center"
      height="100%"
      style={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <Checkbox checked={isChecked} onCheckedChange={handleClick} />
    </Flex>
  );
};

const RecordNameRenderer = (props: ICellRendererParams) => {
  const recordTypeSlug = RECORD_TABLE_TO_SLUG[(props as any).databaseTable];
  return (
    <Link to={`/records/${recordTypeSlug}/${props.data.id}`}>
      {props.value}
    </Link>
  );
};

const fieldToColDef = (field: RecordField): ColDef<Record> => {
  return {
    field: field.apiName as keyof Record,
    headerName: field.name,
    valueFormatter: getFormatterForFieldType(field.type),
    cellRenderer: getRendererForFieldType(field.type),
  };
};

const getColDefs = (recordType: RecordType): ColDef<Record>[] => {
  const colDefs: ColDef<Record>[] = [
    { headerCheckboxSelection: true, cellRenderer: CustomCheckbox, width: 50 },
    {
      field: "id",
      headerName: "ID",
      width: 100,
      cellRenderer: RecordNameRenderer,
      cellRendererParams: {
        databaseTable: recordType.databaseTable,
      },
    },
  ];
  return [...colDefs, ...recordType.fields.map(fieldToColDef)];
};

const RecordsTable: React.FC<{
  recordType: RecordType;
  records: Record[];
  loading: boolean;
  onSelectionChange: (selectedRows: Record[]) => void;
}> = ({ recordType, records, loading, onSelectionChange }) => {
  const handleRowSelected = (event: RowSelectedEvent<Record>) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChange(selectedRows);
  };

  const colDefs = useMemo(() => getColDefs(recordType), [recordType]);

  return (
    <Box height="400px" className="ag-theme-balham ag-grid-box">
      <AgGridReact<Record>
        rowData={records}
        columnDefs={colDefs}
        animateRows={false}
        selection={{
          mode: "singleRow",
          checkboxes: false,
        }}
        onRowSelected={handleRowSelected}
        loading={loading}
        loadingOverlayComponent={() => <Spinner size="3" />}
      />
    </Box>
  );
};

export default Records;
