import {
  Button,
  Dialog,
  Flex,
  Heading,
  Inset,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";
import { IconGeometry } from "@tabler/icons-react";
import React, { useState } from "react";
import { useDesignChanges } from "../../contexts/DesignChangesContext";
import { designChangesProcessDescription } from "../../demoData";

const CreateDesignChangesProcessDialog: React.FC = () => {
  const { setDesignChangesProcess } = useDesignChanges();
  const [open, setOpen] = useState(false);
  const [processName, setProcessName] = useState("Design Changes");
  const [processDescription, setProcessDescription] = useState("");

  const handleCreateProcess = () => {
    const newProcess = {
      id: "design-changes",
      name: processName,
      description: processDescription || designChangesProcessDescription,
      createdAt: new Date(),
      updatedAt: new Date(),
      icon: <IconGeometry />,
    };
    setDesignChangesProcess(newProcess);
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button>Create Process</Button>
      </Dialog.Trigger>
      <Dialog.Content size="2" minWidth="650px" style={{ width: "800px" }}>
        <Dialog.Title size="4">Create Process: Design Changes</Dialog.Title>
        <Flex direction="column" gap="3">
          <Inset side="x">
            <Flex
              gap="4"
              px="4"
              style={{
                borderBottom: "var(--line-border)",
                borderTop: "var(--line-border)",
              }}
            >
              <Flex
                direction="column"
                gap="3"
                py="3"
                pr="4"
                style={{ borderRight: "var(--line-border)" }}
              >
                <Heading size="3">Steps</Heading>
                <Flex direction="column" gap="2" minWidth="100px">
                  <Button
                    variant="ghost"
                    color="iris"
                    data-state="open"
                    style={{
                      justifyContent: "flex-start",
                    }}
                  >
                    <Text weight="bold">1. Basic Information</Text>
                  </Button>
                  <Button
                    variant="ghost"
                    color="gray"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <Text>2. Document Options</Text>
                  </Button>
                  <Button
                    variant="ghost"
                    color="gray"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <Text>3. Confirmation</Text>
                  </Button>
                </Flex>
              </Flex>
              <Flex
                direction="column"
                gap="3"
                py="3"
                flexGrow="1"
                minHeight="200px"
              >
                <label>
                  <Text as="div" size="2" mb="1" weight="bold">
                    Process Name
                  </Text>
                  <TextField.Root
                    value={processName}
                    onChange={(e) => setProcessName(e.target.value)}
                    placeholder="Enter process name"
                  />
                </label>
                <label>
                  <Text as="div" size="2" mb="1" weight="bold">
                    Process Description
                  </Text>
                  <TextArea
                    value={processDescription}
                    onChange={(e) => setProcessDescription(e.target.value)}
                    placeholder="Describe the process"
                  />
                </label>
              </Flex>
            </Flex>
          </Inset>
          <Flex gap="3" mt="1" justify="between">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button onClick={handleCreateProcess}>Next</Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default CreateDesignChangesProcessDialog;
