import { Badge, Text } from "@radix-ui/themes";
import {
  BaseEdge,
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from "@xyflow/react";
import { THEME_COLOR } from "../../theme";

export type CustomEdgeType = Edge<{ label: string }, "custom">;

export default function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style = {},
  markerEnd,
  selected,
}: EdgeProps<CustomEdgeType>) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          strokeWidth: selected ? 2 : 1,
          stroke: selected ? "var(--accent-9)" : "var(--gray-6)",
          filter: selected ? "drop-shadow(0 0 2px var(--accent-a6))" : "none",
        }}
      />
      <EdgeLabelRenderer>
        <Badge
          className="nodrag nopan"
          variant={selected ? "solid" : "surface"}
          color={selected ? THEME_COLOR : "gray"}
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
        >
          <Text size="1" style={{ textTransform: "uppercase" }}>
            {data?.label}
          </Text>
        </Badge>
      </EdgeLabelRenderer>
    </>
  );
}
