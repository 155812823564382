import {
  Badge,
  Box,
  Button,
  Dialog,
  Flex,
  Kbd,
  Tabs,
  Text,
  TextField,
} from "@radix-ui/themes";
import { IconCube, IconFile, IconSearch, IconUser } from "@tabler/icons-react";
import css from "./Search.module.scss";

function Search() {
  const searchTrigger = (
    <Button
      variant="surface"
      color="gray"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "var(--space-1) var(--space-2)",
        borderRadius: "var(--radius-3)",
        boxShadow: "var(--shadow-2)",
      }}
    >
      <Flex align="center" gap="2">
        <IconSearch />
        <Text color="gray">Search</Text>
      </Flex>
      <Flex gap="1">
        <Kbd size="1">⌘</Kbd>
        <Kbd size="1">K</Kbd>
      </Flex>
    </Button>
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger>{searchTrigger}</Dialog.Trigger>
      <Dialog.Content
        size="2"
        maxWidth="500px"
        align="start"
        style={{ marginTop: "150px" }}
      >
        <Flex direction="column" gap="3">
          <TextField.Root
            size="3"
            variant="surface"
            color="gray"
            placeholder="Search everything..."
          >
            <TextField.Slot>
              <IconSearch />
            </TextField.Slot>
          </TextField.Root>
          <Tabs.Root defaultValue="all">
            <Tabs.List>
              <Tabs.Trigger value="all">All</Tabs.Trigger>
              <Tabs.Trigger value="documents">
                <Flex align="center" gap="1">
                  <IconFile />
                  Documents
                  <Badge variant="soft" color="gray">
                    12
                  </Badge>
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="records">
                <Flex align="center" gap="1">
                  <IconCube />
                  Records
                  <Badge variant="soft" color="gray">
                    8
                  </Badge>
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="people">
                <Flex align="center" gap="1">
                  <IconUser />
                  People
                  <Badge variant="soft" color="gray">
                    2
                  </Badge>
                </Flex>
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
          <div className="rt-BaseMenuViewport rt-DropdownMenuViewport">
            <SearchResult title="Document A" />
            <SearchResult title="Document B" />
            <SearchResult title="Document C" />
          </div>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

interface SearchResultProps {
  title: string;
}
function SearchResult({ title }: SearchResultProps) {
  return (
    <Box className={css.searchResult}>
      <Text>{title}</Text>
    </Box>
  );
}

export default Search;
