import { ValueFormatterFunc, ValueFormatterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { RecordField } from "../../demoData";

export const getFormatterForFieldType = (type: RecordField["type"]) => {
  switch (type) {
    case "date":
      return DateFormatter;
    case "timestamp":
      return TimestampFormatter;
    case "number":
      return NumberFormatter;
    case "boolean":
      return BooleanFormatter;
    case "file[]":
      return FileListFormatter;
    default:
      return StringFormatter;
  }
};

export const DateFormatter: ValueFormatterFunc = <T,>(
  params: ValueFormatterParams<T, Date>
) => {
  if (!params.value) {
    return "-";
  }
  return dayjs(params.value).format("ddd, MMM DD, YYYY");
};

export const TimestampFormatter: ValueFormatterFunc = <T,>(
  params: ValueFormatterParams<T, Date>
) => {
  if (!params.value) {
    return "-";
  }
  return dayjs(params.value).format("ddd, MMM DD, YYYY HH:mm A");
};

export const NumberFormatter: ValueFormatterFunc = <T,>(
  params: ValueFormatterParams<T, number>
) => {
  if (!params.value) {
    return "-";
  }
  return params.value.toLocaleString();
};

const currencyFormatter = Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 5,
});
export const CurrencyFormatter: ValueFormatterFunc = <T,>(
  params: ValueFormatterParams<T, number>
) => {
  if (!params.value || isNaN(params.value)) {
    return "-";
  }
  return currencyFormatter.format(params.value);
};

export const StringFormatter: ValueFormatterFunc = <T,>(
  params: ValueFormatterParams<T, string>
) => {
  return params.value || "-";
};

export const BooleanFormatter: ValueFormatterFunc = <T,>(
  params: ValueFormatterParams<T, boolean>
) => {
  debugger;
  if (params.value === undefined || params.value === null) {
    return "-";
  }
  return params.value ? "Yes" : "No";
};

export const FileListFormatter: ValueFormatterFunc = <T,>(
  params: ValueFormatterParams<T, string[]>
) => {
  if (!params.value || params.value.length === 0) {
    return "-";
  }
  return `${params.value.length} file(s)`;
};
