import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Skeleton,
  Text,
} from "@radix-ui/themes";
import { IconChevronRight, IconListDetails } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppToolbar from "../../components/AppToolbar";
import { Record as DbRecord, RECORD_TYPES } from "../../demoData";
import { supabaseRecords } from "../../supabaseClient";

const Record = () => {
  const { recordSlug, recordId } = useParams();
  const [record, setRecord] = useState<DbRecord | null>(null);
  const recordType = recordSlug ? RECORD_TYPES[recordSlug] : undefined;

  useEffect(() => {
    const fetchRecord = async () => {
      if (!recordType || !recordId) return;

      const { data, error } = await supabaseRecords
        .schema("records")
        .from(recordType.databaseTable)
        .select("*")
        .eq("id", recordId)
        .single();

      if (error) {
        console.error("Error fetching record:", error);
      } else {
        setRecord(data);
      }
    };

    fetchRecord();
  }, [recordType, recordId]);

  const isLoaded = recordType && record;

  return (
    <Flex direction="column">
      <AppToolbar
        title={
          <Flex align="center" gap="1">
            <Button variant="ghost" color="gray">
              <IconListDetails />
              <Text>Records</Text>
            </Button>
            <IconChevronRight />
            {isLoaded ? (
              <>
                <Button variant="ghost" color="gray" asChild>
                  <Link to={`/records/${recordType.slug}`}>
                    <Text>{recordType.pluralName}</Text>
                  </Link>
                </Button>
                <IconChevronRight />
                <Text>{recordType.name}</Text>
              </>
            ) : (
              <Skeleton>Record</Skeleton>
            )}
          </Flex>
        }
      />
      <Container
        px="5"
        py={{ md: "5", xl: "7" }}
        align={{ md: "left", xl: "center" }}
      >
        <Flex direction="column" gap="5">
          <Flex direction="column" gap="1">
            <Text
              color="gray"
              size="1"
              weight="bold"
              style={{ textTransform: "uppercase", color: "var(--gray-10)" }}
            >
              Record
            </Text>
            <Heading size="7">
              {isLoaded ? recordType.name : <Skeleton>Record</Skeleton>}
            </Heading>
            <Text color="gray" size="4">
              {isLoaded ? (
                recordType.description
              ) : (
                <Skeleton>Description of this record type</Skeleton>
              )}
            </Text>
          </Flex>
          <Box>
            {isLoaded ? (
              <pre>{JSON.stringify(record, null, 2)}</pre>
            ) : (
              <Skeleton>Record details</Skeleton>
            )}
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Record;
