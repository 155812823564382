import React from 'react';
import ReactDOM from "react-dom/client";

import "@radix-ui/themes/styles.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "./index.css";

import { Theme } from "@radix-ui/themes";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { THEME_COLOR } from "./theme";

import { pdfjs } from "react-pdf";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Theme accentColor={THEME_COLOR}>
      <App />
    </Theme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();