import { Badge, Box, IconButton, Text } from "@radix-ui/themes";
import {
  IconArrowsMaximize,
  IconCube,
  IconCubePlus,
  IconDiscountCheck,
  IconMailForward,
} from "@tabler/icons-react";
import {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import classNames from "classnames";
import { useEffect } from "react";
import DetailCard from "../../components/DetailCard";
import CustomEdge from "../../components/workflow/CustomEdge";
import CustomNode from "../../components/workflow/CustomNode";
import { EdgeType, NodeType } from "../../components/workflow/types";
import css from "./Workflow.module.scss";
const initialNodes: NodeType[] = [
  {
    type: "custom",
    id: "1",
    position: { x: 0, y: 0 },
    data: {
      icon: <IconCubePlus />,
      title: "Record Created",
      description: (
        <Text>
          <Badge size="1" color="gray">
            Engineering Change Request
          </Badge>{" "}
          created
        </Text>
      ),
      whenBadge: true,
    },
  },
  {
    type: "custom",
    id: "2",
    position: { x: 0, y: 140 },
    data: {
      icon: <IconDiscountCheck />,
      title: "Request Approval",
      description: (
        <Text>
          Request approval from{" "}
          <Badge size="1" color="gray">
            Eng Approvers
          </Badge>
        </Text>
      ),
    },
  },
  {
    type: "custom",
    id: "3",
    position: { x: -250, y: 270 },
    data: {
      icon: <IconCube />,
      title: "Modify Record",
      description: (
        <Text>
          Update record status to{" "}
          <Badge size="1" color="green">
            Approved
          </Badge>
        </Text>
      ),
    },
  },
  {
    type: "custom",
    id: "4",
    position: { x: 0, y: 440 },
    data: {
      icon: <IconCube />,
      title: "Modify Record",
      description: (
        <Text>
          Update record status to{" "}
          <Badge size="1" color="red">
            Rejected
          </Badge>
        </Text>
      ),
    },
  },
  {
    type: "custom",
    id: "5",
    position: { x: 300, y: 270 },
    data: {
      icon: <IconMailForward />,
      title: "Send Notification",
      description: "Notify customer of engineering change",
    },
  },
  {
    type: "custom",
    id: "6",
    position: { x: 300, y: 440 },
    data: {
      icon: <IconCube />,
      title: "Modify Record",
      description: (
        <Text>
          Update record status to{" "}
          <Badge size="1" color="green">
            Approved
          </Badge>
        </Text>
      ),
    },
  },
];

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const initialEdges: EdgeType[] = [
  {
    type: "custom",
    id: "1-2",
    source: "1",
    target: "2",
    data: { label: "Then" },
  },
  {
    type: "custom",
    id: "2-3",
    source: "2",
    target: "3",
    data: { label: "Approved" },
  },
  {
    type: "custom",
    id: "2-4",
    source: "2",
    target: "4",
    data: { label: "Rejected" },
  },
  {
    type: "custom",
    id: "2-5",
    source: "2",
    target: "5",
    data: { label: "requires notification" },
  },
  {
    type: "custom",
    id: "5-6",
    source: "5",
    target: "6",
    data: { label: "Then" },
  },
];

export const Workflow: React.FC<{
  isEditing: boolean;
  cancelSemaphore: number;
}> = ({ isEditing, cancelSemaphore }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [setNodes, setEdges, cancelSemaphore]);

  const onConnect = (connection: Connection) => {
    setEdges((eds) => addEdge(connection, eds));
  };

  return (
    <DetailCard
      title="Workflow"
      actions={
        <IconButton variant="ghost" color="gray">
          <IconArrowsMaximize />
        </IconButton>
      }
    >
      <Box style={{ width: "100%", height: "450px" }}>
        <ReactFlow<NodeType, EdgeType>
          className={classNames(css.workflow, {
            [css.editable]: isEditing,
          })}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          elementsSelectable={isEditing}
          nodesDraggable={isEditing}
          nodesConnectable={isEditing}
          edgesFocusable={isEditing}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          snapToGrid
          fitViewOptions={{ maxZoom: 0.75 }}
          proOptions={{ hideAttribution: true }}
        >
          <Background variant={BackgroundVariant.Dots} />
          <Controls showInteractive={false} />
        </ReactFlow>
      </Box>
    </DetailCard>
  );
};
