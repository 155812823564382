import {
  Avatar,
  Box,
  DropdownMenu,
  Flex,
  Heading,
  Inset,
  Separator,
  Text,
} from "@radix-ui/themes";
import { IconLogout, IconSettings, IconUserCircle } from "@tabler/icons-react";
import React from "react";
import {
  useSupabase,
  useSupabaseSession,
} from "../../contexts/SupabaseContext";
import { supabase } from "../../supabaseClient";
import SidebarButton from "./SidebarButton";

const UserMenu: React.FC = () => {
  const session = useSupabaseSession();
  const { setSession } = useSupabase();
  const handleLogout = async () => {
    await supabase.auth.signOut();
    setSession(null);
  };

  const email = session?.user.email;
  const name =
    email === "vipul@meridian.tech" ? "Vipul Shekhawat" : "Alex Dees";
  const avatarSrc =
    email === "vipul@meridian.tech"
      ? "https://www.vipshek.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fme.b8d6331c.png&w=640&q=75"
      : "https://media.licdn.com/dms/image/v2/D5603AQHdsMuSPmzAfQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1708989234221?e=1730332800&v=beta&t=MV7IBiBbEpqwDvhY7GpPdQUaaPa74HHimNjjm9SNfIU";

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <SidebarButton icon={<IconUserCircle />} label={name} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content side="right">
        <Flex gap="4">
          <Avatar size="3" radius="full" src={avatarSrc} fallback={avatarSrc} />
          <Box>
            <Heading size="3" as="h3">
              {name}
            </Heading>
            <Text as="div" size="2" color="gray" mb="2">
              {email}
            </Text>
            <Text as="div" size="2"></Text>
          </Box>
        </Flex>
        <Inset side="x" mx="-2">
          <Separator orientation="horizontal" size="4" mb="2" />
        </Inset>
        <DropdownMenu.Item>
          <IconSettings /> Settings
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleLogout}>
          <IconLogout />
          Log out
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default UserMenu;
