import { BasejumpUserSession, SignedIn, SignedOut } from "@usebasejump/react";
import { BrowserRouter as Router } from "react-router-dom";
import LoggedInApp from "./LoggedInApp";
import { DesignChangesProvider } from "./contexts/DesignChangesContext";
import { SupabaseProvider } from "./contexts/SupabaseContext";
import Login from "./pages/Login";
import { supabase } from "./supabaseClient";

function App() {
  return (
    <BasejumpUserSession supabaseClient={supabase}>
      <SupabaseProvider>
        <DesignChangesProvider>
          <Router>
            <SignedIn>
              <LoggedInApp />
            </SignedIn>
            <SignedOut>
              <Login />
            </SignedOut>
          </Router>
        </DesignChangesProvider>
      </SupabaseProvider>
    </BasejumpUserSession>
  );
}

export default App;
