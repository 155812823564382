import { IconTruck } from "@tabler/icons-react";
import React, { createContext, ReactNode, useContext, useState } from "react";

interface Process {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  icon: React.ReactElement;
}

interface DesignChangesContextType {
  designChangesProcess: Process | null;
  setDesignChangesProcess: (value: Process | null) => void;
}

const DesignChangesContext = createContext<
  DesignChangesContextType | undefined
>(undefined);

export const DesignChangesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [designChangesProcess, setDesignChangesProcess] =
    useState<Process | null>({
      id: "supplier-evaluation",
      name: "Supplier Evaluation",
      description: "Process for evaluating new suppliers during onboarding",
      createdAt: new Date(),
      updatedAt: new Date(),
      icon: <IconTruck />,
    });

  return (
    <DesignChangesContext.Provider
      value={{
        designChangesProcess,
        setDesignChangesProcess,
      }}
    >
      {children}
    </DesignChangesContext.Provider>
  );
};

export const useDesignChanges = () => {
  const context = useContext(DesignChangesContext);
  if (context === undefined) {
    throw new Error(
      "useDesignChanges must be used within a DesignChangesProvider"
    );
  }
  return context;
};
