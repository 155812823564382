import { Badge, Flex, Link, Text } from "@radix-ui/themes";
import { IconExternalLink } from "@tabler/icons-react";
import { ICellRendererParams } from "ag-grid-community";
import { RecordField } from "../../demoData";
import { supabase } from "../../supabaseClient";
import { THEME_COLOR } from "../../theme";

export const getRendererForFieldType = (type: RecordField["type"]) => {
  switch (type) {
    case "enum":
      return BadgeRenderer;
    default:
      return TextRenderer;
  }
};

export const EmailRenderer = (props: ICellRendererParams) => {
  return (
    <Link href={`mailto:${props.data.point_of_contact_email}`}>
      {props.data.point_of_contact_email}
    </Link>
  );
};

export const EmptyValue = ({ text = "No value" }: { text?: string }) => (
  <Text style={{ fontStyle: "italic" }} color="gray">
    {text}
  </Text>
);

export const WebsiteRenderer = (props: ICellRendererParams) => {
  if (!props.data.website) {
    return <EmptyValue />;
  }
  return (
    <Flex align="center" height="100%">
      <WebsiteLink url={props.data.website} />
    </Flex>
  );
};

export const WebsiteLink = ({ url }: { url?: string | null }) => {
  if (!url) {
    return <EmptyValue />;
  }
  return (
    <Link href={url} target="_blank">
      <Flex align="center" gap="1" height="100%">
        <Text>{url.replace(/^(https?:\/\/)?(www\.)?/, "")} </Text>
        <IconExternalLink />
      </Flex>
    </Link>
  );
};

export const BadgeRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue text="-" />;
  }

  return (
    // Hack: ICellRendererParams can't handle cellRendererParams
    <Badge color={(props as any).color ?? "iris"} size="2" radius="large">
      {props.value}
    </Badge>
  );
};

export const StatusRenderer = (props: ICellRendererParams) => {
  return (
    <Flex align="center" height="100%">
      <Status status={props.data.status} />
    </Flex>
  );
};

export const Status = ({ status }: { status: string }) => {
  return (
    <Badge
      color={
        status === "Active"
          ? "green"
          : status === "Pending"
          ? THEME_COLOR
          : "red"
      }
      size="2"
      radius="large"
    >
      {status}
    </Badge>
  );
};

export const TextRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return (
    <Text>
      {props.formatValue ? props.formatValue(props.value) : props.value}
    </Text>
  );
};

export const CertificationRenderer = (props: ICellRendererParams) => {
  const handleClick = async () => {
    const { data, error } = await supabase.storage
      .from("newco-files")
      .createSignedUrl(props.data.certification_file_path, 3600);

    if (error) throw error;

    if (data) {
      window.open(data.signedUrl);
    }
  };

  if (!props.data.certification_file_path) {
    return <EmptyValue />;
  }
  const displayName = props.data.certification_file_path.split("/").pop();
  return (
    <Link onClick={handleClick} style={{ cursor: "pointer" }}>
      {displayName}
    </Link>
  );
};
