import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Heading,
  Text,
} from "@radix-ui/themes";
import {
  IconFileText,
  IconFilter,
  IconPlus,
  IconSortDescending,
  IconTrash,
} from "@tabler/icons-react";
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import AppToolbar from "../../components/AppToolbar";
import { Document, DOCUMENTS } from "../../demoData";

const Documents = () => {
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);

  const documents = useMemo(() => Object.values(DOCUMENTS), []);

  return (
    <Flex direction="column">
      <AppToolbar
        title={
          <Button variant="ghost" color="gray" asChild>
            <Link to="/documents">
              <IconFileText />
              <Text>Documents</Text>
            </Link>
          </Button>
        }
      />
      <Container
        px="5"
        py={{ md: "5", xl: "7" }}
        align={{ md: "left", xl: "center" }}
      >
        <Flex direction="column" gap="2">
          <Flex direction="column" gap="1">
            <Heading size="7">Documents</Heading>
            <Text color="gray" size="4">
              Manage official organization documents while maintaining
              compliance.
            </Text>
          </Flex>
          <DocumentsToolbar selectedDocuments={selectedDocuments} />
          <DocumentsTable
            documents={documents}
            onSelectionChange={setSelectedDocuments}
          />
        </Flex>
      </Container>
    </Flex>
  );
};

const DocumentsToolbar: React.FC<{ selectedDocuments: Document[] }> = ({
  selectedDocuments,
}) => {
  return (
    <Flex justify="between" align="center" py="2">
      <Flex gap="2">
        <Button variant="outline" color="gray">
          <IconSortDescending />
          Sort
        </Button>
        <Button variant="outline" color="gray">
          <IconFilter />
          Filter
        </Button>
      </Flex>
      <DocumentActions
        reload={() => {}}
        selectedDocuments={selectedDocuments}
      />
    </Flex>
  );
};

const DocumentActions: React.FC<{
  reload: () => void;
  selectedDocuments: Document[];
}> = ({ reload, selectedDocuments }) => {
  let actions = [];
  if (selectedDocuments.length > 0) {
    actions.push(
      <DeleteDocumentAction
        key="delete"
        onSuccess={reload}
        selectedDocuments={selectedDocuments}
      />
    );
  } else {
    actions.push(<AddDocumentAction key="add" onSuccess={reload} />);
  }
  return <Flex gap="2">{actions}</Flex>;
};

const DeleteDocumentAction: React.FC<{
  onSuccess: () => void;
  selectedDocuments: Document[];
}> = ({ onSuccess, selectedDocuments }) => {
  return (
    <Button variant="outline" color="gray">
      <IconTrash />
      Delete
    </Button>
  );
};

const AddDocumentAction: React.FC<{
  onSuccess: () => void;
}> = ({ onSuccess }) => {
  return (
    <Button>
      <IconPlus />
      Add document
    </Button>
  );
};

const CustomCheckbox = (props: ICellRendererParams) => {
  const [isChecked, setIsChecked] = useState(props.node.isSelected());

  useEffect(() => {
    const updateChecked = () => {
      setIsChecked(props.node.isSelected());
    };
    props.api.addEventListener("selectionChanged", updateChecked);
    return () => {
      props.api.removeEventListener("selectionChanged", updateChecked);
    };
  }, [props.api, props.node]);

  const handleClick = () => {
    props.node.setSelected(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <Flex
      align="center"
      height="100%"
      style={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <Checkbox checked={isChecked} onCheckedChange={handleClick} />
    </Flex>
  );
};

const DocumentNameRenderer = (props: ICellRendererParams) => {
  return (
    <Link to={`/documents/${props.data.identifier}`}>{props.data.title}</Link>
  );
};

const colDefs: ColDef<Document>[] = [
  { headerCheckboxSelection: true, cellRenderer: CustomCheckbox, width: 50 },
  {
    field: "title",
    headerName: "Title",
    cellRenderer: DocumentNameRenderer,
    width: 220,
  },
  {
    field: "processId",
    headerName: "Linked Process",
    cellRenderer: (props: ICellRendererParams) => (
      <Link to={`/processes/${props.data.processId}`}>
        {props.data.processId
          .split("-")
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
      </Link>
    ),
    width: 150,
  },
  {
    field: "identifier",
    headerName: "ID",
    width: 110,
  },
  { field: "type", headerName: "Type", width: 120 },
  { field: "owner", headerName: "Owner", width: 120 },
  {
    valueGetter: (params: ValueGetterParams<Document, any>) => {
      return params.data?.revisions[0].createdAt;
    },
    valueFormatter: (params) =>
      params.value ? params.value.toLocaleDateString() : "-",
    headerName: "Last revision",
    flex: 1,
  },
];

const DocumentsTable: React.FC<{
  documents: Document[];
  onSelectionChange: (selectedRows: Document[]) => void;
}> = ({ documents, onSelectionChange }) => {
  const handleRowSelected = (event: RowSelectedEvent<Document>) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChange(selectedRows);
  };

  return (
    <Box height="400px" className="ag-theme-balham ag-grid-box">
      <AgGridReact<Document>
        rowData={documents}
        columnDefs={colDefs}
        animateRows={false}
        selection={{
          mode: "multiRow",
          checkboxes: false,
          headerCheckbox: false,
        }}
        onRowSelected={handleRowSelected}
      />
    </Box>
  );
};

export default Documents;
