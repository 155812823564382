import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  DataList,
  Dialog,
  Flex,
  Grid,
  Heading,
  IconButton,
  ScrollArea,
  Strong,
  Tabs,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import {
  IconArrowsMaximize,
  IconChevronLeft,
  IconChevronRight,
  IconDownload,
  IconEye,
  IconFileText,
  IconHistory,
  IconId,
  IconPencil,
  IconSquareCheck,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { Document as PDFDocument, Page } from "react-pdf";
import { Link, useParams } from "react-router-dom";
import AppToolbar from "../../components/AppToolbar";
import DetailCard from "../../components/DetailCard";
import { DOCUMENTS } from "../../demoData";
import DocumentRevisions from "./DocumentRevisions";

const Document: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isFullScreenPreviewOpen, setIsFullScreenPreviewOpen] = useState(false);

  const document = id ? DOCUMENTS[id] : undefined;

  const title = document?.title || "My document";
  const description =
    document?.description || "This is a description of my document";

  const currentRevision = document?.revisions[0];

  const fullScreenPreviewDialog = (
    <Dialog.Root
      open={isFullScreenPreviewOpen}
      onOpenChange={setIsFullScreenPreviewOpen}
    >
      <Dialog.Trigger>
        <Tooltip content="Maximize preview">
          <IconButton
            color="gray"
            variant="ghost"
            aria-label="Maximize preview"
            onClick={() => setIsFullScreenPreviewOpen(true)}
          >
            <IconArrowsMaximize />
          </IconButton>
        </Tooltip>
      </Dialog.Trigger>
      <Dialog.Content
        size="2"
        style={{
          maxWidth: "850px",
          height: "80vh",
        }}
      >
        <Dialog.Title>Document Preview</Dialog.Title>
        <ScrollArea style={{ height: "70vh" }}>
          <DocumentPreview
            pdfUrl={currentRevision?.fileUrl ?? ""}
            width={800}
          />
        </ScrollArea>
      </Dialog.Content>
    </Dialog.Root>
  );

  return (
    <Flex direction="column">
      <AppToolbar
        title={
          <Flex align="center" gap="1">
            <Button variant="ghost" color="gray" asChild>
              <Link to="/documents">
                <IconFileText />
                <Text>Documents</Text>
              </Link>
            </Button>
            <IconChevronRight />
            <Text>{title}</Text>
          </Flex>
        }
      />
      <Container
        px="5"
        py={{ md: "5", xl: "7" }}
        align={{ md: "left", xl: "center" }}
      >
        <Flex direction="column" gap="5">
          <Flex align="center" justify="between">
            <Flex direction="column" gap="1">
              <Text
                color="gray"
                size="1"
                weight="bold"
                style={{ textTransform: "uppercase", color: "var(--gray-10)" }}
              >
                {document?.type ?? "Document"}
              </Text>
              <Heading size="7">{title}</Heading>
              <Text color="gray" size="4">
                {description}
              </Text>
            </Flex>
            <Flex align="center" gap="2">
              <Button variant="soft" color="gray">
                <IconPencil />
                Edit
              </Button>
            </Flex>
          </Flex>
          <Tabs.Root defaultValue="overview">
            <Tabs.List>
              <Tabs.Trigger value="overview">
                <Flex align="center" gap="1">
                  <IconFileText />
                  Overview
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="revisions">
                <Flex align="center" gap="1">
                  <IconHistory />
                  Revisions
                </Flex>
              </Tabs.Trigger>
              <Tabs.Trigger value="compliance">
                <Flex align="center" gap="1">
                  <IconSquareCheck />
                  Compliance
                </Flex>
              </Tabs.Trigger>
            </Tabs.List>
            <Box pt="3">
              <Tabs.Content value="overview">
                <Heading size="5" mt="2" mb="4">
                  Overview
                </Heading>
                <Grid columns="2" gap="4" maxWidth="900px">
                  <DetailCard
                    title="Basic Information"
                    icon={<IconId />}
                    actions={
                      <Tooltip content="Edit information">
                        <IconButton
                          color="gray"
                          variant="ghost"
                          aria-label="Edit information"
                        >
                          <IconPencil />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <DataList.Root>
                      <DataList.Item>
                        <DataList.Label>Identifier</DataList.Label>
                        <DataList.Value>
                          <Badge>{document?.identifier}</Badge>
                        </DataList.Value>
                      </DataList.Item>
                      <DataList.Item>
                        <DataList.Label>Document Title</DataList.Label>
                        <DataList.Value>{document?.title}</DataList.Value>
                      </DataList.Item>
                      <DataList.Item>
                        <DataList.Label>Type</DataList.Label>
                        <DataList.Value>
                          <Badge>{document?.type}</Badge>
                        </DataList.Value>
                      </DataList.Item>
                      <DataList.Item>
                        <DataList.Label>Compliance Clause</DataList.Label>
                        <DataList.Value>
                          <Badge>{document?.complianceClause}</Badge>
                        </DataList.Value>
                      </DataList.Item>
                      <DataList.Item>
                        <DataList.Label>Owner</DataList.Label>
                        <DataList.Value>
                          <Flex align="center" gap="2">
                            <Avatar fallback="EO" size="1" />
                            Engineering Owners
                          </Flex>
                        </DataList.Value>
                      </DataList.Item>
                    </DataList.Root>
                  </DetailCard>
                  <DetailCard
                    title="Preview"
                    icon={<IconEye />}
                    actions={
                      <>
                        <Tooltip content="Download document">
                          <IconButton
                            color="gray"
                            variant="ghost"
                            aria-label="Download document"
                            onClick={() => {
                              window.open(currentRevision?.fileUrl, "_blank");
                            }}
                          >
                            <IconDownload />
                          </IconButton>
                        </Tooltip>
                        {fullScreenPreviewDialog}
                      </>
                    }
                  >
                    <ScrollArea style={{ height: "400px" }}>
                      <DocumentPreview
                        pdfUrl={currentRevision?.fileUrl ?? ""}
                        width={400}
                      />
                    </ScrollArea>
                  </DetailCard>
                </Grid>
              </Tabs.Content>
              <Tabs.Content value="revisions">
                <Heading size="5" mt="2" mb="4">
                  Revisions
                </Heading>
                <DocumentRevisions revisions={document?.revisions ?? []} />
              </Tabs.Content>
              <Tabs.Content value="compliance">
                <Heading size="5" mt="2" mb="4">
                  Compliance
                </Heading>
              </Tabs.Content>
            </Box>
          </Tabs.Root>
        </Flex>
      </Container>
    </Flex>
  );
};

const DocumentPreview: React.FC<{ pdfUrl: string; width: number }> = ({
  pdfUrl,
  width,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const corsProxyUrl = `https://corsproxy.io/?${encodeURIComponent(pdfUrl)}`;

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const controls = (
    <Flex
      align="center"
      justify="center"
      p="2"
      gap="3"
      position="sticky"
      bottom="4"
      style={{
        backgroundColor: "var(--color-surface)",
        border: "var(--line-border)",
        borderRadius: "var(--radius-2)",
        margin: "0 auto",
        maxWidth: "150px",
      }}
    >
      <IconButton
        variant="ghost"
        color="gray"
        onClick={() => setPageNumber(pageNumber - 1)}
        disabled={pageNumber === 1}
      >
        <IconChevronLeft />
      </IconButton>
      <Text size="2">
        Page <Strong>{pageNumber}</Strong> of <Strong>{numPages}</Strong>
      </Text>
      <IconButton
        variant="ghost"
        color="gray"
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber === numPages}
      >
        <IconChevronRight />
      </IconButton>
    </Flex>
  );

  return (
    <Box position="relative">
      <PDFDocument file={corsProxyUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          width={width}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </PDFDocument>
      {controls}
    </Box>
  );
};

export default Document;
