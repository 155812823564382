import {
  Button,
  Dialog,
  Flex,
  Heading,
  Popover,
  Select,
  Separator,
  Strong,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";
import { layoutPropDefs } from "@radix-ui/themes/dist/cjs/props/layout.props";
import { paddingPropDefs } from "@radix-ui/themes/dist/cjs/props/padding.props";
import { GetPropDefTypes } from "@radix-ui/themes/dist/cjs/props/prop-def";
import {
  IconArrowRightRhombus,
  IconBell,
  IconCertificate,
  IconCheckbox,
  IconChevronDown,
  IconCompass,
  IconFileCheck,
  IconFileText,
  IconHelp,
  IconListCheck,
  IconListDetails,
  IconUsersPlus,
  IconWorldLongitude,
} from "@tabler/icons-react";
import React, { PropsWithChildren } from "react";
import { useDesignChanges } from "../../contexts/DesignChangesContext";
import { RECORD_TYPES } from "../../demoData";
import Search from "./Search";
import SidebarButton from "./SidebarButton";
import UserMenu from "./UserMenu";

const Sidebar: React.FC = () => {
  const { designChangesProcess } = useDesignChanges();

  return (
    <Flex
      direction="column"
      justify="between"
      width="250px"
      height="100%"
      style={{
        backgroundColor: "var(--gray-2)",
        borderRight: "var(--line-border)",
      }}
    >
      <Flex
        py="2"
        px="4"
        align="center"
        justify="between"
        style={{ borderBottom: "var(--line-border)" }}
      >
        <Button variant="ghost" color="gray">
          <IconWorldLongitude style={{ width: "18px", height: "18px" }} />
          <Text size="3" weight="bold">
            Meridian
          </Text>
          <IconChevronDown />
        </Button>
      </Flex>

      <Flex direction="column" flexGrow="1">
        <SidebarSection bottomBorder px="4" py="2">
          <Search />
          <Notifications />
        </SidebarSection>

        <SidebarSection
          header={{
            icon: <IconCertificate />,
            label: "Compliance",
          }}
          bottomBorder
        >
          <SidebarButton
            to="/navigator"
            label="Navigator"
            icon={<IconCompass />}
          />
          <SidebarButton
            to="/documents"
            label="Documents"
            icon={<IconFileText />}
          />
          <SidebarButton
            to="/approvals"
            label="Approvals"
            icon={<IconCheckbox />}
          />
          {/* <SidebarButton
            to="/events"
            label="Events"
            icon={<IconCalendarMonth />}
          /> */}
        </SidebarSection>
        <SidebarSection
          header={{
            icon: <IconArrowRightRhombus />,
            label: "Processes",
          }}
          bottomBorder
        >
          <SidebarButton
            to="/processes/document-control"
            label="Control of Documents"
            icon={<IconFileCheck />}
          />
          <SidebarButton
            to="/processes/record-control"
            label="Control of Records"
            icon={<IconListCheck />}
          />

          {designChangesProcess && (
            <SidebarButton
              to={`/processes/${designChangesProcess.id}`}
              label={designChangesProcess.name}
              icon={designChangesProcess.icon}
            />
          )}
        </SidebarSection>

        <SidebarSection
          header={{
            icon: <IconListDetails />,
            label: "Records",
          }}
          bottomBorder
        >
          {Object.values(RECORD_TYPES).map((recordType) => (
            <SidebarButton
              key={recordType.slug}
              to={`/records/${recordType.slug}`}
              label={recordType.shortName}
              icon={recordType.icon}
            />
          ))}
        </SidebarSection>
      </Flex>

      <SidebarSection topBorder py="2">
        <InviteTeammates />
        <Support />
        <UserMenu />
      </SidebarSection>
    </Flex>
  );
};

function Notifications() {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <SidebarButton icon={<IconBell />} label="Notifications" />
      </Popover.Trigger>
      <Popover.Content width="360px" side="right">
        <Flex direction="column" gap="2">
          <Heading size="3">Recent Notifications</Heading>
          <Text size="2">You have 3 new notifications</Text>
          <Separator size="4" />
          <Flex direction="column" gap="2">
            <Text size="2">
              <Strong>John Doe</Strong> mentioned you in a comment
            </Text>
            <Text size="2">
              <Strong>Jane Smith</Strong> assigned you a task
            </Text>
            <Text size="2">
              New supplier <Strong>Tech Innovations</Strong> added
            </Text>
          </Flex>
          <Button size="1" variant="soft">
            View all notifications
          </Button>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}

function InviteTeammates() {
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <SidebarButton icon={<IconUsersPlus />} label="Invite teammates" />
      </Dialog.Trigger>
      <Dialog.Content size="2">
        <Dialog.Title>Invite Teammates</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Invite your colleagues to collaborate.
        </Dialog.Description>
        <Flex direction="column" gap="3">
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Email Address
            </Text>
            <TextField.Root placeholder="colleague@example.com" size="2" />
          </label>
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Role
            </Text>
            <Select.Root defaultValue="editor">
              <Select.Trigger />
              <Select.Content>
                <Select.Item value="viewer">Viewer</Select.Item>
                <Select.Item value="editor">Editor</Select.Item>
                <Select.Item value="admin">Admin</Select.Item>
              </Select.Content>
            </Select.Root>
          </label>
          <Flex gap="3" mt="4" justify="end">
            <Button size="2">Send Invitation</Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function Support() {
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <SidebarButton icon={<IconHelp />} label="Help and Support" />
      </Dialog.Trigger>
      <Dialog.Content size="2">
        <Dialog.Title>Submit a Help Request</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Describe your issue and we'll get back to you as soon as possible.
        </Dialog.Description>
        <Flex direction="column" gap="3">
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Subject
            </Text>
            <TextField.Root placeholder="Issue title" size="2" />
          </label>
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Description
            </Text>
            <TextArea
              placeholder="Provide more details about your issue"
              size="2"
            />
          </label>
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Priority
            </Text>
            <Select.Root defaultValue="medium">
              <Select.Trigger />
              <Select.Content>
                <Select.Item value="low">Low</Select.Item>
                <Select.Item value="medium">Medium</Select.Item>
                <Select.Item value="high">High</Select.Item>
              </Select.Content>
            </Select.Root>
          </label>
          <Flex gap="3" mt="4" justify="end">
            <Button size="2">Submit Request</Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

interface SidebarSectionProps
  extends PropsWithChildren,
    GetPropDefTypes<typeof paddingPropDefs & typeof layoutPropDefs> {
  header?: {
    icon: React.ReactElement;
    label: string;
  };
  topBorder?: boolean;
  bottomBorder?: boolean;
}

function SidebarSection({
  children,
  header,
  topBorder = false,
  bottomBorder = false,
  ...props
}: SidebarSectionProps) {
  let maybeHeaderElement: React.ReactNode;
  if (header) {
    maybeHeaderElement = (
      <Flex align="center" gap="1" mx="1" style={{ color: "var(--gray-10)" }}>
        {header.icon}
        <Text
          size="1"
          weight="bold"
          style={{ textTransform: "uppercase", color: "var(--gray-10)" }}
        >
          {header.label}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      {topBorder && <Separator size="4" />}
      <Flex direction="column" gap="2" p="4" {...props}>
        {maybeHeaderElement}
        <Flex direction="column" gap="3" mt="2">
          {children}
        </Flex>
      </Flex>
      {bottomBorder && <Separator size="4" />}
    </>
  );
}

export default Sidebar;
