import { Badge, Link } from "@radix-ui/themes";
import { IconExternalLink } from "@tabler/icons-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { BadgeRenderer, TextRenderer } from "../../components/tables/renderers";
import { DocumentRevision } from "../../demoData";
import { THEME_COLOR } from "../../theme";

interface DocumentRevisionsProps {
  revisions: DocumentRevision[];
}

const DocumentRevisions: React.FC<DocumentRevisionsProps> = ({ revisions }) => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Number",
      field: "number",
      cellRenderer: BadgeRenderer,
      width: 100,
      sort: "desc",
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: (params: ICellRendererParams) => {
        const color =
          params.value === "Released"
            ? "green"
            : params.value === "Pending Approval"
            ? THEME_COLOR
            : "gray";
        return (
          <Badge color={color} size="1" radius="large">
            {params.value}
          </Badge>
        );
      },
      width: 140,
    },
    {
      headerName: "Author",
      field: "createdBy",
      cellRenderer: TextRenderer,
      width: 120,
    },
    {
      headerName: "Approver",
      field: "approvedBy",
      cellRenderer: TextRenderer,
      width: 120,
    },
    {
      headerName: "Created",
      field: "createdAt",
      valueFormatter: (params) => params.value.toLocaleDateString() ?? "",
      width: 120,
    },
    {
      headerName: "Approved",
      field: "approvedAt",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleDateString() : "",
      cellRenderer: TextRenderer,
      width: 120,
    },
    {
      headerName: "File",
      field: "fileUrl",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Link href={params.value}>
            {params.value.split("/").pop()} <IconExternalLink />
          </Link>
        );
      },
      flex: 1,
    },
  ];

  return (
    <div
      className="ag-theme-balham ag-grid-box small"
      style={{ height: 400, width: "100%" }}
    >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={revisions}
        animateRows={false}
      />
    </div>
  );
};

export default DocumentRevisions;
