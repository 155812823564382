import { Flex, Text } from "@radix-ui/themes";
import React from "react";
import { useLocation } from "react-router-dom";

const AppToolbar: React.FC<{ title?: React.ReactNode }> = ({ title }) => {
  const location = useLocation();
  const titleElement = title ? title : location.pathname.substring(1) || "Home";

  return (
    <Flex
      px="4"
      py="2"
      align="center"
      justify="between"
      position="sticky"
      top="0"
      style={{
        backgroundColor: "var(--color-background)",
        zIndex: 1000,
        borderBottom: "var(--line-border)",
      }}
    >
      <Text size="3" weight="bold" style={{ textTransform: "capitalize" }}>
        {titleElement}
      </Text>
    </Flex>
  );
};

export default AppToolbar;
