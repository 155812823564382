import { Box, Flex } from "@radix-ui/themes";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { Link } from "react-router-dom";

export interface ComplianceMapping {
  framework: string;
  clause: string;
  description: string;
}

const ComplianceMappingRenderer: React.FC<{ data: ComplianceMapping }> = (
  props
) => {
  return (
    <Link to={`/compliance/${props.data.framework}/${props.data.clause}`}>
      {props.data.framework} Clause {props.data.clause}
    </Link>
  );
};

const colDefs: ColDef<ComplianceMapping>[] = [
  {
    field: "framework",
    headerName: "Framework",
    cellRenderer: ComplianceMappingRenderer,
    width: 220,
  },
  {
    field: "clause",
    headerName: "Clause",
    width: 110,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
];

interface ProcessComplianceProps {
  mappings: ComplianceMapping[];
}

const ProcessCompliance: React.FC<ProcessComplianceProps> = ({ mappings }) => {
  return (
    <Flex direction="column" gap="3">
      <Box height="400px" className="ag-theme-balham ag-grid-box">
        <AgGridReact<ComplianceMapping>
          rowData={mappings}
          columnDefs={colDefs}
          animateRows={false}
        />
      </Box>
    </Flex>
  );
};

export default ProcessCompliance;
