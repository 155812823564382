import { Box, Button, ButtonProps, Flex, Text } from "@radix-ui/themes";
import React, { PropsWithChildren } from "react";
import { Link, useLocation } from "react-router-dom";
import { THEME_COLOR } from "../../theme";

interface SidebarButtonProps extends ButtonProps {
  label: string;
  icon: React.ReactElement;
  to?: string;
}

const SidebarButton: React.FC<PropsWithChildren<SidebarButtonProps>> =
  React.forwardRef((props, ref) => {
    const { label, icon, to } = props;

    const location = useLocation();
    const isActive = to && location.pathname.endsWith(to);

    const element = (
      <Flex align="center" gap="1" py="2px" ml={!isActive ? "1" : undefined}>
        <Box height="var(--space-4)" width="var(--space-4)">
          {icon &&
            React.cloneElement(icon, {
              style: {
                color: isActive ? "var(--accent-11)" : "var(--gray-11)",
              },
            })}
        </Box>
        <Text
          weight={isActive ? "bold" : "medium"}
          style={{ lineHeight: "1.2" }}
        >
          {label}
        </Text>
      </Flex>
    );
    return (
      <Button
        {...props}
        asChild={!!to}
        ref={ref as any}
        variant="ghost"
        size="2"
        color={isActive ? THEME_COLOR : "gray"}
        data-state={isActive ? "open" : "closed"}
        style={{
          justifyContent: "flex-start",
          borderLeft: isActive
            ? "var(--space-1) solid var(--accent-11)"
            : undefined,
        }}
      >
        {to ? <Link to={to}>{element}</Link> : element}
      </Button>
    );
  });

export default SidebarButton;
