import { Box, Flex } from "@radix-ui/themes";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { Link } from "react-router-dom";
import { Document } from "../../demoData";

const DocumentNameRenderer: React.FC<{ data: Document }> = (props) => {
  return (
    <Link to={`/documents/${props.data.identifier}`}>{props.data.title}</Link>
  );
};

const colDefs: ColDef<Document>[] = [
  {
    field: "title",
    headerName: "Title",
    cellRenderer: DocumentNameRenderer,
    width: 220,
  },
  {
    field: "identifier",
    headerName: "ID",
    width: 110,
  },
  { field: "type", headerName: "Type", width: 120 },
  { field: "owner", headerName: "Owner", width: 120 },
  { field: "description", headerName: "Description", flex: 1 },
];

interface ProcessDocumentsProps {
  documents: Document[];
}

const ProcessDocuments: React.FC<ProcessDocumentsProps> = ({ documents }) => {
  return (
    <Flex direction="column" gap="3">
      <Box height="400px" className="ag-theme-balham ag-grid-box">
        <AgGridReact<Document>
          rowData={documents}
          columnDefs={colDefs}
          animateRows={false}
        />
      </Box>
    </Flex>
  );
};

export default ProcessDocuments;
